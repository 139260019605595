import React from 'react';
import Layout from "../components/Layout";
import NavThree from "../components/NavThree";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Login from "../components/Login";

const LoginPage = () => {
    return (
        <Layout pageTitle="Login To Student Profile | LUTH Schools">
            <NavThree />
            <PageHeader title="" />
            <Login />
            <Footer />
        </Layout>
    );
};

export default LoginPage;
