import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PasswordRevealer from 'password-revealer';
import { FirebaseContext } from './Firebase';
import { Loader } from '@mantine/core';
import { navigate, Link } from 'gatsby';

const Login = () => {
  // PasswordRevealer('#password').init();
  const [isLoading, setIsLoading] = useState(false);
  const [wrongDetails, setWrongDetails] = useState(false);
  const { firebase, user } = useContext(FirebaseContext);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (form) => {
    setIsLoading(true);
    firebase
      .login({ email: form.email, password: form.password })
      .then((data) => {
        setIsLoading(false);
        navigate('/profile');
      })
      .catch((error) => {
        setIsLoading(false);
        setWrongDetails(true);

        console.log('error', error);
        // if (error.code === 'auth/wrong-password') {
        //   setWrongDetails(true);
        // }
      });
  };

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>Login To Student Profile</h2>
                <form onSubmit={handleSubmit(onSubmit)} className='reply-form'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <input
                        type='email'
                        name='email'
                        placeholder='Enter email'
                        className='reply-form__field'
                        ref={register({ required: true })}
                      />
                      {errors.email && (
                        <span className='errorText'>
                          <i className='fas fa-exclamation-triangle'></i> This
                          field is required
                        </span>
                      )}
                    </div>
                    <div className='col-lg-12'>
                      <input
                        type='password'
                        name='password'
                        placeholder='Your password'
                        ref={register({ required: true })}
                        className='reply-form__field'
                      />
                      {errors.password && (
                        <span className='errorText'>
                          <i className='fas fa-exclamation-triangle'></i> This
                          field is required
                        </span>
                      )}
                      {wrongDetails && (
                        <span className='errorText'>
                          <i className='fas fa-question-circle'></i> Incorrect
                          Login Details. Please try again
                        </span>
                      )}
                    </div>
                    <div className='col-lg-12' style={{ textAlign: 'right' }}>
                      <Link to='/forgot-password'>Forgot your password?</Link>
                    </div>
                    <div className='col-lg-12'>
                      {isLoading ? (
                        <Loader size='xl' color='orange' variant='dots' />
                      ) : (
                        <button
                          className='reply-form__btn thm-btn'
                          type='submit'
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
